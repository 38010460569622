// import Axios from 'axios';
import Axios from '../../Pages/Dashboard/httpInterceptor';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../../config/constant';
import Loader from '../../React-Loader/loader';

export const TransferModal =({data ,onTransferCompleted, bal})=> {

  var transferData = data;
  console.log(data)
    const [show, setShow] = useState(false);
    const [amount, setAmount] = useState('')
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [availableAmount , setAvailableAmount] = useState(bal);
    var [orderTypeFrom, setOrdereTypeFrom ]  = useState("MARGIN") ;
    var [orderTypeTo, setOrdereTypeTo ]  = useState("SPOT") ;
    const changeorderTypeFrom = ()=>{
          if(orderTypeFrom == 'SPOT'){
            setOrdereTypeFrom('MARGIN')
            setOrdereTypeTo('SPOT')
            setAvailableAmount(bal)
          }else{
            setOrdereTypeFrom('SPOT')
            setOrdereTypeTo('MARGIN')
            getSotBalance()
          }
    } 

    const getSotBalance = ()=>{
      Axios.post(BASE_URL + `userBalance/get` , {
        "userId": localStorage.getItem('userDetails')
      })
        .then(async res => {
          if (res.data.message == "success") {
            setAvailableAmount(res.data.data[transferData.tokenSymbol].toFixed(6))
          }})
    }



    const handleSubmit = ()=>{
      if(amount > 0){
        if(Number(amount) <= Number(availableAmount)){
      let senddata = {
        "userId": localStorage.getItem('userDetails') ,
        "type": orderTypeFrom == "SPOT" ? 0 : 1 ,
        "amount": Number(amount),
        "walletCode" :  transferData.tokenSymbol
      } 
      console.log('transfer dat ==',senddata )
      Axios.post(BASE_URL+"user/update/marginSpotBalance" , senddata).then((result)=>{
        console.log(result)
        if(result.data.statusCode == 200){
          onTransferCompleted()
          toast.success(result.data.message);
          handleClose()
          setAmount('')
        }else{
          toast.error(result.data.message)
          handleClose()
          setAmount('')
        }
      }).catch((error)=>{
        toast.error(error)
      })
    }else{
      toast.warning("amount is greater than available balance")
    }
    }else{
      toast.warning("Enter valid amount")
    }
    }

  return (
    <>
      <ToastContainer />
     
      <Button variant="primary" onClick={handleShow} size="sm">
        Transfer
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Transfer</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='row' style={{marginTop:17 , marginBottom:25}}>
                <div className='col-md-5' style={{textAlign : 'center'}}> 
                    <div>
                          <span style={{border:'1px solid'  , width:120 , height:35 , borderRadius : 15}} className="btn btn-warning">{orderTypeFrom}</span>
                    </div>
                </div>
                <div className='col-md-2' style={{textAlign : 'center', marginTop:25}}> 
                        <div>
                          <span onClick={changeorderTypeFrom} style={{fontSize:30, color:'#edc215'}} >
                          &#8633;
                            </span>
                        </div>
                </div>
                <div className='col-md-5' style={{textAlign : 'center'}}> 
                    <div>
                    <span style={{border:'1px solid'  , width:120 , height:35 , borderRadius : 15}} className="btn btn-warning">{orderTypeTo}</span>

                    </div>
                </div>
            </div>


            <div style={{display: 'flex', alignItems:'center'}} className="form-control">
                <div>
                <img src={"/coinicon/"+data.tokenSymbol+".png" } width="30px" height="30px"/>
                </div>
                <div ><h5 style={{marginLeft:10}}>{data.tokenSymbol}<span className="name"> ({data.tokenName})</span></h5></div>
            </div>

            <div className='row'>
            <div className='col-md-12'>
            <div style={{ paddingTop: 20}}>
              <div>

            <label htmlFor="">Amount <span style={{color:'#edc215'}}> ( {availableAmount} {data.tokenSymbol} Available )</span></label>
              </div>
                <input placeholder='Amount'  className='form-control' type="number" min={1} value={amount} onChange={(e) =>setAmount(e.target.value) } />
            </div>
            </div>
            </div>
        </Modal.Body>
        <div style={{textAlign:'right', padding: 16}}>
        <hr />
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Transfer
          </Button>
        </div>
      </Modal>
    </>
  );
}
