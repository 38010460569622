import React from "react";

import { Route, Switch } from 'react-router-dom';

import ActivityLog from "./Pages/Dashboard/activitylog";
import Dashboard from "./Pages/Dashboard/dashboard";
import Deposit from "./Pages/Dashboard/deposit";
import Referral from "./Pages/Dashboard/referral";
import Setting from "./Pages/Dashboard/setting";
import Transactions from "./Pages/Dashboard/transactions";
import Withdraw from "./Pages/Dashboard/withdraw";
import Homepage from "./Pages/Homepage/homepage";
import Forgotpassword from "./Pages/Login/forgotpassword";
import Login from "./Pages/Login/login";
import Resetpassword from "./Pages/Login/resetpassword";
import News from './Pages/News';
import ViewBlog from "./Pages/News/viewBlog";
import NewsDetails from './Pages/NewsDetails';
import Activations from "./Pages/Register/activations";
import Register from "./Pages/Register/register";
import Support from "./Pages/Support";
// import kyc from "./Pages/Dashboard/kyc";
import CurrencyConverter from "./Pages/currencyConverter/currencyConverter";
import CanclOrderMargin from './Pages/Dashboard//cancelOrderMargin';
import BorrowHistory from './Pages/Dashboard/borrowing';
import BuyECNA from "./Pages/Dashboard/buyECNA";
import CoinTransaction from "./Pages/Dashboard/coinTransaction";
import Feesubscription from "./Pages/Dashboard/feesubscription";
import InrDeposit from "./Pages/Dashboard/inr-deposit";
import { KycHandler } from "./Pages/Dashboard/kycHandler";
import Logout from "./Pages/Dashboard/logout";
import DashboardMargin from './Pages/Dashboard/marginDashboard';
import OpenOrderMargin from './Pages/Dashboard/openOrderMargin';
import OrderHistoryMargin from './Pages/Dashboard/orderHistoryMargin';
import PersonalDetails from "./Pages/Dashboard/personalDetails";
import SellECNA from "./Pages/Dashboard/sellECNA";
import FeesDetails from "./Pages/fees/fees";
import announcement from "./Pages/Homepage/announcement";
import announcementDetail from "./Pages/Homepage/announcementDetail";
import contact from "./Pages/Homepage/contactUs";
import faq from "./Pages/Homepage/faq";
import privacy from "./Pages/Homepage/privacy";
import terms from "./Pages/Homepage/terms";
import AdPost from "./Pages/p2p/AdPost/AdPost";
import ComingSoon from "./Pages/p2p/ComingSoon";
import PToPHome from "./Pages/p2p/Home/home";
import P2PWallet from "./Pages/p2p/Home/P2PWallet";
import P2pOrderDetails from "./Pages/p2p/Orders/OrderDetails";
import MyP2pOrders from "./Pages/p2p/Orders/Orders";
import MarginTrade from "./Pages/Trade/marginTrade";
import SpotTrade from "./Pages/Trade/trade";
// import PickASymbol from "./Pages/PickASymbol/buySell";
// import SelectGame from "./Pages/SelectGame/selectGame";
// import ConfirmRegistration  from "./Pages/Register/Confirm";
// import LoginWIthToken from "./Pages/LoginWIthToken/loginWithToken";

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/news&blog" component={News} />
            <Route exact path="/ViewBlog" component={ViewBlog} />
            <Route exact path="/newsdetails/:id" component={NewsDetails} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/resetpassword" component={Resetpassword} />
            <Route exact path="/forgotpassword" component={Forgotpassword} />
            <Route exact path="/invite/:refcode" component={Register} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/dashboardMargin" component={DashboardMargin} />
            <Route exact path="/openOrderMargin" component={OpenOrderMargin} />
            <Route exact path="/orderHistoryMargin" component={OrderHistoryMargin} />
            <Route exact path="/canclOrderMargin" component={CanclOrderMargin} />
            <Route exact path="/borrowHistory" component={BorrowHistory} />
            <Route exact path="/referral" component={Referral} />
            <Route exact path="/transactions" component={Transactions} />
            <Route exact path="/deposit/:coinCode" component={Deposit} />
            <Route exact path="/deposit" component={Deposit} />
            <Route exact path="/inrdeposit/:coinCode" component={InrDeposit} />
            <Route exact path="/withdraw/:coinCode" component={Withdraw} />
            <Route exact path="/withdraw" component={Withdraw} />
            <Route exact path="/activitylog" component={ActivityLog} />
            <Route exact path="/setting" component={Setting} />
            <Route exact path="/feesubscription" component={Feesubscription} />
            {/* <Route exact path="/trade/:coinCode/:pairCode" component={Trade} /> */}
            {/* <Route exact path="/trade" component={Trade} /> */}
            <Route exact path="/margin-trade/:coinCode/:pairCode/:type" component={MarginTrade} />
            <Route exact path="/trade/:coinCode/:pairCode/:type" component={SpotTrade} />

            <Route exact path="/margintrade" component={MarginTrade} />
            <Route exact path="/activations" component={Activations} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/announcement/:blogid" component={announcementDetail} />
            <Route exact path="/announcement" component={announcement} />
            <Route exact path="/kyc" component={KycHandler} />
            <Route exact path="/fees" component={FeesDetails} />
            <Route exact path="/terms" component={terms} />
            <Route exact path="/privacy" component={privacy} />
            <Route exact path="/contact" component={contact} />
            <Route exact path="/faq" component={faq} />
            <Route exact path="/personaldetails" component={PersonalDetails} />
            {/* <Route exact path="/pickasymbol" component={PickASymbol} />
            <Route exact path="/selectgame" component={SelectGame} />
            <Route exact path="/confirmRegistration" component={ConfirmRegistration} />
            <Route exact path="/LoginWithToken?token=:token&redirectToFirstPlay=true" component={LoginWIthToken} /> */}
            <Route exact path="/convert" component={CurrencyConverter} />
            <Route exact path="/coinTransaction" component={CoinTransaction} />
            <Route exact path="/buyecna" component={BuyECNA} />
            <Route exact path="/sellecna" component={SellECNA} />
            <Route exact path="/p2p" component={PToPHome} />
            <Route exact path="/p2p-coming-soon" component={ComingSoon} />
            <Route exact path="/p2pWallets" component={P2PWallet} />
            <Route exact path="/adPost/:type/:tokenSymbol" component={AdPost} />
            <Route exact path="/p2p-orders" component={MyP2pOrders} />
            <Route exact path="/p2p-orders/:id" component={P2pOrderDetails} />

        </Switch>
    );
};

export default Routes;
