export const decimalData = {
    "BTC": {
        priceDecimal: 2,
        amountDecimal: 5,
        D: 6
    },
    "ETH": {
        priceDecimal: 2,
        amountDecimal: 4,
        D: 4
    },
    "BNB": {
        priceDecimal: 1,
        amountDecimal: 3,
        D: 3
    },
    "TRX": {
        priceDecimal: 5,
        amountDecimal: 1,
        D: 3
    },
    "XRP": {
        priceDecimal: 4,
        amountDecimal: 0,
        D: 3
    },
    "XLM": {
        priceDecimal: 4,
        amountDecimal: 0,
        D: 3
    },
    "DOGE": {
        priceDecimal: 5,
        amountDecimal: 0,
        D: 3
    },
    "BTT": {
        priceDecimal: 6,
        amountDecimal: 1,
        D: 3
    },
    "VET": {
        priceDecimal: 5,
        amountDecimal: 1,
        D: 3
    },
    "RSR": {
        priceDecimal: 5,
        amountDecimal: 1,
        D: 3
    },
    "VTHO": {
        priceDecimal: 6,
        amountDecimal: 0,
        D: 3
    },
    "ENJ": {
        priceDecimal: 3,
        amountDecimal: 1,
        D: 3
    },
    "LINK": {
        priceDecimal: 3,
        amountDecimal: 2,
        D: 3
    },
    "MATIC": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "SHIB": {
        priceDecimal: 8,
        amountDecimal: 0,
        D: 3
    },
    "USDT": {
        priceDecimal: 8,
        amountDecimal: 0,
        D: 3
    },
    "INR": {
        priceDecimal: 4,
        amountDecimal: 0,
        D: 2
    },
    "MKR": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "PEPE": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "ANKR": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "1INCH": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "FTM": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "SAND": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "AAVE": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "UNI": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "XEN": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "DAI": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "FESS": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "ZRX": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "TEL": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "MANA": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "RSR": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "DENT": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "HOT": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "GRT": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "ECNAUSDT": {
        D: 3
    },
    "ECNABTC": {
        D: 6
    },
    "ECNAETH": {
        D: 4
    },
    "ECNAINR": {
        D: 2
    },
    "SOL": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "TRUMP": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
    "MELANIA": {
        priceDecimal: 4,
        amountDecimal: 1,
        D: 3
    },
}
